import { render, staticRenderFns } from "./FormCartao.vue?vue&type=template&id=5e3321d7&scoped=true&"
import script from "./FormCartao.vue?vue&type=script&lang=js&"
export * from "./FormCartao.vue?vue&type=script&lang=js&"
import style0 from "./FormCartao.vue?vue&type=style&index=0&id=5e3321d7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e3321d7",
  null
  
)

export default component.exports