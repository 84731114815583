<template>
	<ValidationObserver
		ref="form_dados_cartao"
	>
		<form
			name="form-cartao"
			class="form-cartao"
			@click="() => false"
		>
			<h3>Dados</h3>
			<fieldset class="inputs">
				<ValidationProvider
					v-slot="{ errors }"
					class="input-nome-cartao"
					name="Nome"
					rules="required"
				>
					<b-field
						label="Nome impresso no cartão"
						class="input-nome-cartao"
						:type="errors.length > 0 ? 'is-danger no-icon' : ''"
					>
						<b-input v-model="form.nome_cartao" />
					</b-field>
					<p class="help is-danger">
						{{ errors[0] }}
					</p>
				</ValidationProvider>
				<ValidationProvider
					v-slot="{ errors }"
					class="input-numero-cartao"
					name="Numero"
					rules="required"
				>
					<b-field
						label="Número do cartão"
						class="input-numero-cartao"
						:type="errors.length > 0 ? 'is-danger no-icon' : ''"
					>
						<b-input
							v-model="form.numero_cartao"
							v-mask="'####-####-####-####'"
						/>
						<img
							v-if="logoCartao"
							:src="logoCartao"
							class="logo-cartao"
						/>
					</b-field>
					<p class="help is-danger">
						{{ errors[0] }}
					</p>
				</ValidationProvider>
				<ValidationProvider
					v-slot="{ errors }"
					class="input-validade"
					name="Validade"
					rules="required|validade"
				>
					<b-field
						label="Validade do cartão"
						class="input-validade"
						:type="errors.length > 0 ? 'is-danger no-icon' : ''"
					>
						<b-input
							v-model="form.validade"
							v-mask="'##/##'"
						/>
					</b-field>
					<p class="help is-danger">
						{{ errors[0] }}
					</p>
				</ValidationProvider>
				<ValidationProvider
					v-slot="{ errors }"
					class="input-cvv"
					name="CVV"
					rules="required|min:3"
				>
					<b-field
						label="CVV"
						class="input-cvv"
						:type="errors.length > 0 ? 'is-danger no-icon' : ''"
					>
						<b-input
							type="password"
							v-model="form.cvv"
							v-mask="['###', '####']"
						/>
					</b-field>
					<p class="help is-danger">
						{{ errors[0] }}
					</p>
				</ValidationProvider>
			</fieldset>
		</form>
	</ValidationObserver>
</template>

<script>
import { checkCreditCardType } from '@commons/helpers.js';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
	name: 'FormCartaoComponentEBRADI',
	components: {
		ValidationProvider,
		ValidationObserver
	},
	data() {
		return {
			logoCartao: null,
			form: {
				bandeira: null,
				cvv: null,
				nome_cartao: null,
				numero_cartao: null,
				validade: null,
			}
		}
	},
	methods: {
		getAllData() {
			return this.form;
		},
	},
	watch: {
		async 'form.numero_cartao'(value) {
			let result = checkCreditCardType(value.replace(/\D/g, ''));

			this.form.bandeira = result;

			if (result) {
				this.logoCartao = (await import(`@assets/pagamento/cartao_bandeira/${result}.svg`)).default
			} else {
				this.logoCartao = null
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.form-cartao {
	h3 {
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		color: #B2B2B2;
	}

	.inputs {
		display: flex;
		margin-top: 10px;
		margin-bottom: 22px;
		justify-content: space-between;
		align-items: flex-start;
		gap: 6px;

		.input-nome-cartao {
			height: 58px;
			max-width: 440px;
			width: 100%;
		}

		.input-numero-cartao {
			max-width: 280px;
			position: relative;
			width: 100%;

			&::v-deep {
				.input {
					width: calc(100% - 60px);
				}
			}
		}

		.input-validade {
			max-width: 140px;
			width: 100%;
			height: 58px;
		}

		.input-cvv {
			max-width: 70px;
			width: 100%;
		}
	}

	.logo-cartao {
		position: absolute;
		right: 18px;
		top: 18px;
		width: 40px;
		height: 24px;
	}

	::v-deep .field {
		border: solid 1px #B2B2B2;
		border-radius: 12px;
		padding: 10px 16px;

		.label {
			font-size: 10px;
			font-weight: 400;
			line-height: 12px;
			margin: 0px;
			color: #B2B2B2;
		}

		.input {
			font-size: 14px;
			border: none!important;
			padding: 0px!important;
			height: auto!important;
			border-radius: 0px!important;

			&:focus {
				box-shadow: none!important;
			}
		}
	}
}

@media screen and (max-width: 900px)  {
	.form-cartao {
		::v-deep .field {
			.input {
				font-size: 12px;
			}
		}
	}

	.inputs {
		flex-wrap: wrap;
		justify-content: flex-start!important;

		& > div {
			height: 48px!important;
		}

		.input-nome-cartao {
			width: 100%;
			max-width: 100%!important;
		}

		.input-numero-cartao {
			max-width: 100%!important;
			width: 100%;
		}
	}
}

</style>
