<template>
	<div class="resumo-compra">
		<div class="resumo-compra-cursos">
			<h2 v-show="!gratuito">RESUMO DA COMPRA</h2>
			<h2 v-show="gratuito">RESUMO</h2>
			<div class="nome-produto">
				<h4>{{ curso.title }}</h4>
			</div>
		</div>
		<div class="resumo-compra-valores" :class="gratuito && 'gratuito'">
			<div class="resumo-valor">
				<ul v-for="(item, index) in resumoCompra" :key="index">
					<li class="resumo-valor-li">
						<span>{{ item.label }}:</span>
						<span class="bar" />
						<span class="valor">{{ item.valor }}</span>
					</li>
					<li v-if="item.children && item.children.length > 0">
						<ul>
							<li
								v-for="(child, childIndex) in item.children"
								:key="childIndex"
								class="resumo-valor-li"
							>
								<span>{{ child.label }}:</span>
								<span class="bar" />
								<span class="valor">
									{{ child.valor }}
								</span>
							</li>
							<!-- <li v-if="item.label === 'Sub Total'">
								(*{{ item.children.some(child => child.tipo === 'D') ? 'Desconto' : 'Acréscimo' }} aplicado sobre o subtotal)
							</li> -->
						</ul>
					</li>
				</ul>
			</div>
			<div class="valor-final">
				<div v-if="valorFinal">
					<h3>Valor final:</h3>
				</div>
				<div class="resumo-final">
					<h2>{{ valorFinal ? valorFinal : "Gratuito" }}</h2>
					<h5 v-if="parcelamento.length">Parcelado em {{ parcelamento[0] }}x de <span>{{ parcelamento[1] }}</span></h5>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { currencyFormatter, capitalizeIgnoringArticles } from '@commons/helpers';

export default {
	name: 'ComprarInfoComponentEBRADI',
	props: {
		resumo: {
			type: Object,
			required: true,
		},
		curso: {
			type: Object,
			required: true,
		},
		gratuito: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {

		}
	},
	computed: {
		resumoCompra() {
      const result = [];
      let currentTitle = null;

			if(this.resumo.resumo_compra) {
				this.resumo.resumo_compra.forEach((item) => {
					if (item.tipo === 'T') {
						currentTitle = {
							...item,
							children: [],
							label: capitalizeIgnoringArticles(item.label),
							valor: currencyFormatter(item.valor),
						};
						result.push(currentTitle);
					} else if (currentTitle && (item.tipo === 'D' || item.tipo === 'A')) {
						currentTitle.children.push({
							...item,
							label: capitalizeIgnoringArticles(item.label),
							valor: currencyFormatter(item.valor),
						});
					}
				});
			}

      return result;
    },
		valorFinal() {
			return this.resumo.valor_final ? currencyFormatter(this.resumo.valor_final) : 0;
		},
		parcelamento() {
			let parcelamento = [];
			if (this.resumo.label) {
				parcelamento = this.resumo.label.match(/\d+(?:\.\d{3})*(?:,\d+)?/g);
				parcelamento[1] = currencyFormatter(parcelamento[1].replace('.', '').replace(',', '.'));
			}
			return parcelamento;
		}
	}
}
</script>

<style lang="scss" scoped>
.resumo-compra {
	display: flex;
	justify-content: space-around;
	align-items: stretch;
	gap: 8px;
	padding-top: 48px;
	border-top: 0.5px solid var(--primary);

	&-cursos {
		width: calc(50% - 2.5px);
		padding: 0px 24px;

		h2 {
			font-size: 20px;
			font-weight: 700;
			line-height: 24px;
			margin-bottom: 20px;
		}

		div {
			margin-bottom: 20px;

			h4 {
				font-size: 20px;
				font-weight: 700;
				line-height: 24px;
			}
		}

		.nome-produto {
			color: var(--secondary-400);
		}
	}

	&-valores {
    align-items: stretch;
		border-left: 1px solid var(--primary);
		border-radius: 6px;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 32px;
		width: calc(50% - 2.5px);

		&.gratuito {
			align-items: center;
		}
	}
}

.resumo-valor {
	& > ul {
		padding-bottom: 18px;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		margin-top: 20px;
		border-bottom: 1px solid var(--secondary-400);
		color: #E9E5D8;

		&:last-child {
			border-bottom: none;
		}

		span.bar {
			border-color: #E9E5D8;
		}

		& > li > ul > li {
			font-size: 14px;
			font-weight: 600;
			color: #E9E5D8;

			span {
				color: #B2B2B2;
			}
		}

		& > li > ul {
			margin-left: 36px;
			line-height: 28px;
			font-weight: 400;
			color: #B2B2B2;
		}

		.resumo-valor-li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 16px;

			&:first-child {
				flex-grow: 1;
			}

			& > span.bar {
				flex-grow: 2;
				height: 1px;
				width: max-content;
				background: var(--medium-gray);
			}
		}
	}
}

.valor-final {
	padding-top: 16px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	color: var(--secondary-400);

	h3 {
		font-size: 24px;
		line-height: 24px;
		font-weight: 400;
	}

	.resumo-final {
		h2 {
			font-size: 36px;
			line-height: 40px;
			font-weight: 900;
			text-align: right;
		}

		h5 {
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
			color: var(--medium-gray);

			span {
				color: var(--secondary-400);
			}
		}
	}
}

@media screen and (max-width: 900px)  {
	.resumo-compra {
		flex-wrap: wrap;
		border-top: none;

		&-cursos {
			width: 100%;
		}

		&-valores {
			width: 100%;

			border-left: none;
		}
	}
}
</style>
