<template>
	<div>
		<div class="ebradi-checkbox" @click="openText">
			<div class="label">
				<svg
					v-if="texto !== null"
					width="10"
					height="8"
					viewBox="0 0 10 8"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M4.152 6.6432L0.956249 1.53C0.539969 0.863951 1.01881 0 1.80425 0H8.19575C8.98119 0 9.46003 0.86395 9.04375 1.53L5.848 6.6432C5.45633 7.26987 4.54367 7.26987 4.152 6.6432Z"
						fill="#33C1D5"
					/>
				</svg>
				<div v-else class="ball" />
				<label :for="name">{{ label }}</label>
			</div>

			<span
				v-if="loading"
				class="loader_b mr-4"
			/>

			<b-checkbox
				v-else
				:id="name"
				v-model="checkbox"
				:disabled="disabled || loading || (openToAllow ? isDisabled : false)"
				:name="name"
			/>
		</div>
		<div
			v-if="texto !== null && view"
			ref="scrollContainer"
			class="texto"
			v-html="texto"
		/>
		<p
			v-if="errors.length > 0"
			class="help is-danger"
		>
			{{ errors[0] }}
		</p>
	</div>
</template>

<script>
export default {
	name: 'CheckBoxComponentEBRADI',
	props: {
		name: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		value: {
			type: Boolean,
			default: false,
		},
		openToAllow: {
			type: Boolean,
			required: false,
			default: false
		},
		texto: {
			type: String,
			required: false,
			default: null
		},
		errors: {
			type: Array,
			required: false,
			default: () => []
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			checkbox: false,
			view: false,
			isDisabled: true,
			first: false
		}
	},
	watch: {
		value(value) {
			this.checkbox = value;

			if (!value) {
				this.view = false;
			}
		},
		checkbox(value) {
			this.$emit('input', value);
		}
	},
	mounted() {
		this.checkbox = this.value;

		if (!this.openToAllow) {
			this.isDisabled = false;
		}
	},
	methods: {
		openText(event) {
			if (event.target.nodeName !== 'SPAN' && this.texto?.length) {
				this.isDisabled = false
				this.view = !this.view
			}
			
			if (event.target.nodeName === 'SPAN' && !this.first) {
				this.first = true
				event.preventDefault()
				this.isDisabled = false
				this.view = true
			}
		},
	}
}
</script>

<style lang="scss" scoped>
$background: #14181A;

.ebradi-checkbox {
	background: $background;
	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 16px;
	border-radius: 12px;
	cursor: pointer!important;

	.label {
		display: flex;
		align-items: center;
		margin: 0px;
		cursor: pointer!important;

		.ball {
			display: block;
			border-radius: 4px;
			height: 8px;
			background: var(--turquoise-blue);
			width: 8px;
			cursor: pointer!important;
		}

		label {
			cursor: pointer!important;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: var(--medium-gray);
			margin-left: 15px;
		}
	}
}

.texto {
	display: block;
	overflow-y: auto;
	margin: 22px 22px;
	padding: 22px;
	height: 262px;

	&::v-deep {
		* {
			background: transparent!important;
			border: none!important;
			box-shadow: none!important;
		}

		table {
			margin-top: 20px;
		}

		strong {
			color: #777!important;
		}
	}

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 5px;
	}
}

@media screen and (max-width: 900px)  {
	.ebradi-checkbox {
		.label {
			label {
				font-size: 12px;
			}
		}
	}

	.texto {
		font-size: 12px;
	}
}

.loader_b {
	width: 20px;
}

</style>
